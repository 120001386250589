import { createClient, MenuType, buildMegaMenu } from '../../prismicio';
import { PrismicRichText, SliceZone } from '@prismicio/react';
import { components } from '../../slices';
import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next';
import '@aws-amplify/ui-react/styles.css';
import Layout from '@components/layout';
import Hero from '@components/hero';

type PageProps = InferGetServerSidePropsType<typeof getServerSideProps>;

type PageParams = { uid: string };

export default function Page({ menu, page, footer, termlinks }: PageProps) {
  return (
    <Layout footer={footer} termlinks={termlinks} page={page} menu={menu}>
      <Hero
        title={page.data.title}
        text={page.data.subtitle}
        backgroundImage={page.data.image}
        backgroundVideo={page.data.hero_video}
        videoCopyright={page.data.hero_video_copyright}
      />
      <SliceZone slices={page.data.slices} components={components} />
    </Layout>
  );
}

// Fetch content from prismic
export async function getServerSideProps({
  params,
  locale,
  previewData,
}: GetServerSidePropsContext<PageParams>) {
  const client = createClient({ previewData });

  try {
    // Check the page. This will break if page doesn't exists.
    const page = await client.getByUID('landing', params!.uid, { lang: locale });

    // Do not break if any of this doesn't exist
    const results = await Promise.allSettled([
      client.getSingle('main_menu', { lang: locale }),
      client.getSingle('footer', { lang: locale }),
      client.getSingle('terms_and_conditions', { lang: locale }),
    ]);

    const [main_menu, footer, termlinks] = results.map(result => {
      return result.status === 'fulfilled' ? result.value : []
    });

    let menu: MenuType|Array<any> = [];
    if (results[0].status === 'fulfilled') {
      menu = await buildMegaMenu(main_menu, previewData);
    }

    return {
      props: { menu, page, footer, termlinks },
    };
  } catch {
    // Return 404 if no page with uid
    return {
      notFound: true,
    };
  }
}
